<template>
  <div class="mt-5">
                <router-link to="/login" class="call-button font-normal text-xl">Login to get started</router-link>
    </div>
</template>

<script> 
export default {
     methods: {
        
    },
    }


</script>

<style>

</style>